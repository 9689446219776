import { useState } from "react";
import CheckNullAndEmpty, { MandatoryField, isTokenValid } from "../utils";
import { json, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { ApiFunctions } from "../PostApiPromise";
import { socket } from "../pages/dashboard";
import { Bars } from "react-loader-spinner";

const services = ["Website Development", "On Page SEO"];
const InsertFollowUp = ({ TempBData, setFormVisible, id }) => {
  const [Date, SetDate] = useState("");
  const [Email, SetEmail] = useState("");
  const [WhatsappNumber, setWhatsappNumber] = useState("");
  const [SelectedServices, setSelectedServices] = useState([]);
  const [Bill, setBill] = useState(0);
  const [Remarks, setRemarks] = useState("");
  const [Services, setServices] = useState(services);
  const [BtnLoading, setBtnLoading] = useState(false);
  const navigate = useNavigate();

  const SaveFollowUp = () => {
    setBtnLoading(true);
    isTokenValid(navigate)
      .then((data) => {
        const body = {
          DataId: TempBData._id,
          UserId: data._id,
          Remarks: Remarks,
          Date: Date,
        };

        CheckNullAndEmpty(body, MandatoryField.FollowUp.Insert)
          .then((res) => {
            ApiFunctions.FollowUpPost(body)
              .then((res) => {
                Swal.fire({
                  title: res.Message,
                  icon: "success",
                  confirmButtonText: "ok",
                });
                setFormVisible("");
                socket.emit("RemoveFromData");

                socket.disconnect();
                socket.connect();
                setBtnLoading(false);
              })
              .catch((err) => {
                Swal.fire({
                  title: err.Message,
                  icon: "error",
                  confirmButtonText: "ok",
                });
                setBtnLoading(false);
              });
          })
          .catch((err) => {
            setBtnLoading(false);
            Swal.fire({
              title: "Error!",
              text: err.Message,
              icon: "error",
              confirmButtonText: "ok",
            });
          });
      })
      .catch(() => {
        setBtnLoading(false);
      });
  };

  const updateFollowUp = () => {
    ApiFunctions.UpdateFollowUpPost({ Id: id, Date: Date, Remarks })
      .then((res) => {
        Swal.fire({
          title: res.Message,
          icon: "success",
          confirmButtonText: "ok",
        });
        setFormVisible("");
      })
      .catch((err) => {
        Swal.fire({
          title: "Error!",
          text: err.Message,
          icon: "error",
          confirmButtonText: "ok",
        });
      });
  };
  return (
    <>
      <div id="followUpForm" class="row mt-4">
        <div class="col-lg-12 mb-lg-0 mb-4">
          <div class="card">
            <div class="card-header pb-0">
              <div class="d-flex align-items-center">
                <p class="mb-0">Follow Up</p>
              </div>
            </div>
            <div class="card-body">
              {/* <p class="text-uppercase text-sm">User Information</p> */}
              <div class="row">
                <div class="col-md-6">
                  <div class="mb-3">
                    <label class="form-label">Date</label>
                    <input
                      class="form-control"
                      type="date"
                      onfocus="focused(this)"
                      onfocusout="defocused(this)"
                      onChange={(e) => SetDate(e.target.value)}
                      value={Date}
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="mb-3">
                    <label for="example-text-input" class="form-label">
                      Remarks
                    </label>
                    <textarea
                      class="form-control"
                      type="text"
                      onfocus="focused(this)"
                      onfocusout="defocused(this)"
                      onChange={(e) => setRemarks(e.target.value)}
                      value={Remarks}
                    />
                  </div>
                </div>
              </div>
              <hr class="horizontal dark" />

              <div class="row">
                <div class="col-md-6">
                  <button
                    onClick={() => {
                      BtnLoading?
                      Swal.fire({
                        title: "Please wait",
                        icon: "warning",
                        confirmButtonText: "ok",
                        timer: 1500

                      }):
                      id ? updateFollowUp() : SaveFollowUp()
                    }}
                    class="btn btn-primary m-1 d-flex justify-content-center align-items-center"
                  >
                    {BtnLoading ? (
                      <Bars
                        height="20"
                        width="50"
                        color="#fff"
                        ariaLabel="bars-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                      />
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InsertFollowUp;
