import { jwtDecode } from "jwt-decode";
import Cookies from "universal-cookie";


function CheckNullAndEmpty(Body, MandatoryFields) {
    return new Promise((res, rej) => {
      let NullParams = "";
      let InvalidTypes = "";
     let BodyKeys = Object.keys(Body);
  
      const Bodylength = BodyKeys.length;
  
      BodyKeys.map((Key, index) => {
        var SingleKeyValue = Body[`${Key}`];
        var type = MandatoryFields[Key];
        if (typeof type == "undefined") {
          type = typeof SingleKeyValue;
        }
  
        if (
          SingleKeyValue === "null" ||
          SingleKeyValue == null ||
          SingleKeyValue === ""
        ) {
          if (Bodylength == index + 1) {
            NullParams += `${Key}`;
          } else {
            NullParams += `${Key}, `;
          }
        } else if (typeof SingleKeyValue != type) {
          if (Bodylength == index + 1) {
            InvalidTypes += `${Key}`;
          } else {
            InvalidTypes += `${Key}, `;
          }
        }
        if (Bodylength == index + 1) {
          console.log(NullParams);
          if (NullParams != "") {
            rej({
              Message: `These fields are empty or null. ${NullParams}`,
              Code: "01",
            });
          } else if (InvalidTypes != "") {
            rej({
              Message: `Data types of these fields are not valid. ${InvalidTypes}`,
              Code: "01",
            });
          } else {
            res({ Code: "00" });
          }
        }
      });
    });
  }



  export default CheckNullAndEmpty;





  const CreateUpdateBy = ["CreatedBy", "UpdatedBy"];

const MandatoryField = {
  FollowUp: {
    Insert: {
      DataId: "number",
      UserId: "number",
      Remarks: "string",
      Date: "string",
    },
    Update: {
      Id: "number",
    },
    Get: {
      Id: "number",
      isAdmin: "boolean",
    },
    Delete: {
      Id: "number",
    },
  },

  Lead: {
    Insert: {
      Name: "string",
      BusinessName: "string",
      Email: "string",
      Phone: "string",
      ArrayOfItems: "object",
      TotalBill: "number",

      DataId: "number",
      UserId: "number",
      Remarks: "string",
    },
    Update: {
      Id: "number",
    },
    Get: {
      Id: "number",
      isAdmin: "boolean",
    },
    Delete: {
      Id: "number",
    },
  },

  Users: {
    Insert: {
      Name: "string",
      PhoneNumber: "string",
      Email: "string",
      Password: "string",
      UserTypeId: "number",
    },
    Login: {
      PhoneNumber: "string",
      Password: "string",
    },
    Update: {
      Id: "number",
    },
    Delete: {
      Id: "number",
      UserId: "number",
    },
    List: {
      BranchId: "string",
    },
  },

  Suppliers: {
    Insert: {
      Name: "string",
      PhoneNumber: "string",
      Email: "string",
      BranchId: "number",
    },
    List: {
      BranchId: "string",
    },
    Detail: {
      SupplierId: "string",
    },
    Delete: {
      Id: "number",
      UserId: "number",
    },
  },

  Categories: {
    Insert: {
      Name: "string",
      IconImageBase64: "string",

      BranchId: "number",
    },
    Update: {
      Id: "number",
      IconImageBase64: "string",
    },
    List: {
      BranchId: "string",
    },
    Delete: {
      Id: "number",
    },
  },

  Products: {
    Insert: {
      CategoryId: "number",
      Name: "string",
      Barcode: "string",
      BranchId: "number",
    },
    List: {
      BranchId: "string",
    },
    Delete: {
      Id: "number",
      UserId: "number",
    },
    Update: {
      Id: "number",
    },
    BarcodeSearch: {
      Barcode: "string",
      BranchId: "string",
    },
  },

  Purchase: {
    Insert: {
      ArrayOfItems: "object",
      Paid: "number",
      TotalBill: "number",
      SupplierId: "number",
      BranchId: "number",
      PaymentTypeId: "number",
      TaxInclusive: "number",
    },
    PaymentInsert: {
      PurchaseId: "number",
      Paid: "number",
      PaymentTypeId: "number"
    },
    List: {
      BranchId: "string",
      StartDate: "string",
      EndDate: "string",
      SupplierId: "string"
    },
    ItemList: {
      PurchaseId: "string",
    },
    Delete: {
      Id: "number",
      UserId: "number",
    },
    Update: {
      Id: "number",
    },
  },

  PurchaseReturn: {
    Insert: {
      PurchaseId: "number",
      ArrayOfItems: "object",
      TotalCost: "number",
      BranchId: "number",
      Paid: "number",
      PayTypeIdPaid: "number",
      Received: "number",
      PayTypeIdReceived: "number"
    },
    InArrayOfItems: {
      ItemId: "number",
      Quantity: "number",
      Cost: "number",
    },
    List: {
      BranchId: "string",
      StartDate: "string",
      EndDate: "string",
    },
  },

  Damage: {
    Insert: {
      ArrayOfItems: "object",
      BranchId: "number",
    },
    InArrayOfItems: {
      ItemId: "number",
      Quantity: "number",
      RecoverCost: "number",
    },
    List: {
      BranchId: "string",
      StartDate: "string",
      EndDate: "string",
    },
  },

  ExpensesCategory: {
    Insert: {
      Name: "string",
      BranchId: "number",
    },
    List: {
      BranchId: "string",
    },
    Delete: {
      Id: "number",
      UserId: "number",
    },
  },

  Expense: {
    Insert: {
      Name: "string",
      Description: "string",
      Cost: "number",
      ExpensesCatogoryId: "number",
      BranchId: "number",
    },
    List: {
      BranchId: "string",
      StartDate: "string",
      EndDate: "string",
    },
    Delete: {
      Id: "number",
      UserId: "number",
    },
  },

  Customer: {
    Insert: {
      Name: "string",
      PhoneNumber: "string",
      Email: "string",
      BranchId: "number",
    },
    List: {
      BranchId: "string",
    },
    Delete: {
      Id: "number",
      UserId: "number",
    },
  },


  OrderStatus: {
    Insert: {
      Name: "string",
    },
    Delete: {
      Id: "number",
      UserId: "number",
    },
  },

  PointsAndTax: {
    Insert: {
      Point: "number",
      Value: "number",
      TaxPercentage: "number",
    },
    Delete: {
      Id: "number",
      UserId: "number",
    },
  },


  Sales: {
    Insert: {
      PaymentTypeId: "number",
      OrderStatusId: "number",
      OrderDetailArray: "object",
      BillDetailArray: "object",
      PaymentDetailArray: "object",
      TotalBill: "number",
      CustomerId: "number",
      BranchId: "number",
    },

    OrderDetailArray: {
      ItemId: "number",
      Quantity: "number",
      Price: "number",
    },

    BillDetailArray: {
      SubTotal: "number",
      Tax: "number",
      Discount: "number",
      CouponDiscount: "number",
      Total: "number",
    },

    PaymentDetailArray: {
      CashPay: "number",
      CardPay: "number",
      WalletPay: "number",
      PointUse: "number"
    },

    List: {
      BranchId: "string",
      StartDate: "string",
      EndDate: "string",
    },
    Delete: {
      Id: "number",
      UserId: "number",
    },
  },


  Token: {
    Refresh: {
      refreshToken: "string",
    },
  },
};



const isTokenValid = (navigate) =>{
    return new Promise((res, rej)=>{
        const cookies = new Cookies(null, { path: "/" });
        const token = cookies.get("token");
        if (token) {
          const decoded = jwtDecode(token);
    
          if (decoded.exp * 1000 < new Date().getTime()) {
            navigate("/signin");
          } else {
            res(decoded)
          }
        }
        else{
          navigate("/signin");
        }
    })
   
}



export {
    MandatoryField,
    isTokenValid
}
