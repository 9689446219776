var basic = {
  options: {
    chart: {
      id: "basic-bar",
    },
    xaxis: {
      categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
    },
  },
  series: [
    {
      name: "series-1",
      data: [30, 40, 45, 50, 49, 60, 70, 91],
    },
  ],
};

var breakup = {
  color: "#adb5bd",
  series: [38, 40, 25],
  labels: ["2022", "2021", "2020"],
  chart: {
    width: 180,
    type: "donut",
    fontFamily: "Plus Jakarta Sans', sans-serif",
    foreColor: "#adb0bb",
  },
  plotOptions: {
    pie: {
      startAngle: 0,
      endAngle: 360,
      donut: {
        size: "75%",
      },
    },
  },
  stroke: {
    show: false,
  },

  dataLabels: {
    enabled: false,
  },

  legend: {
    show: false,
  },
  colors: ["#5D87FF", "#ecf2ff", "#F9F9FD"],

  responsive: [
    {
      breakpoint: 991,
      options: {
        chart: {
          width: 150,
        },
      },
    },
  ],
  tooltip: {
    theme: "dark",
    fillSeriesColor: false,
  },
};

const chartData = { breakup, basic };
export default chartData;
