import Cookies from "universal-cookie";

const host = "https://api.swiftsalesync.world";

const LoginApi = `/User/Login`;
const InsertLeadApi = `/Lead/Insert`;
const FollowUpInsert = `/FollowUp/Insert`;
const FollowUpListApi = `/FollowUp/List`;
const FollowUpUpdateApi = `/FollowUp/Update`;
const FollowUpDeleteApi = `/FollowUp/Delete`;
const DataUpdate = `/Data/Update`;
const LeadListApi = `/Lead/List`;

const Apis = {
  LoginApi,
  InsertLeadApi,
  FollowUpInsert,
  FollowUpListApi,
  DataUpdate,
  FollowUpDeleteApi,
  FollowUpUpdateApi,
  LeadListApi
};

export default Apis;

const GetMethod = (Api) => {
  return new Promise((res, rej) => {
    const cookies = new Cookies(null, { path: "/" });
    const token = cookies.get("token");
    fetch(`${host}${Api}`, {
      headers: {
        Authorization: token,
      },
    })
      .then((res) => res.json())
      .then(({ Code, Data, Message, Error }) => {
        if (Code == "00") {
          res({ Data });
        } else {
          rej({ Code, Message });
        }
      })
      .catch((err) => {
        rej(null);
      });
  });
};

const PostMethod = (Api, body) => {
  const cookies = new Cookies(null, { path: "/" });
  const token = cookies.get("token");
  return new Promise((res, rej) => {
    fetch(`${host}${Api}`, {
      method: "POST",
      headers: {
        Authorization: token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((response) => {
        const { Code, Data, Message, Error } = response;
        if (Code == "00") {
          res(response);
        } else {
          rej(response);
        }
      })
      .catch((err) => {
        rej({ Message: "Connection error!" });
      });
  });
};

const DeleteMethod = (Api, body) => {
  const cookies = new Cookies(null, { path: "/" });
  const token = cookies.get("token");
  return new Promise((res, rej) => {
    fetch(`${host}${Api}`, {
      method: "DELETE",
      headers: {
        Authorization: token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((response) => {
        const { Code, Data, Message, Error } = response;
        if (Code == "00") {
          res(response);
        } else {
          rej(response);
        }
      })
      .catch((err) => {
        rej({ Message: "Connection error!" });
      });
  });
};

export { GetMethod, PostMethod, DeleteMethod, host };
