import { useEffect, useState } from "react";
import { ApiFunctions } from "../PostApiPromise";
import Swal from "sweetalert2";
import Cookies from "universal-cookie";
import { Link } from "react-router-dom";

import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { Bars } from "react-loader-spinner";
const SignIn = () => {
  const navigate = useNavigate();

  const [PhoneNumber, setPhoneNumber] = useState("");
  const [Password, setPassword] = useState("");
  const [showPass, setShowPass] = useState(false);
  const [BtnLoading, setBtnLoading] = useState(false);

  useEffect(() => {
    const cookies = new Cookies(null, { path: "/" });
    const token = cookies.get("token");
    if (token) {
      const decoded = jwtDecode(token);

      if (decoded.exp * 1000 < new Date().getTime()) {
      } else {
        navigate("/Dashboard");
      }
    } else {
    }
  });
  const Submit = () => {
    setBtnLoading(true);
    if (PhoneNumber == "") {
      Swal.fire({
        title: "Error!",
        text: "Phone Number is empty",
        icon: "error",
        confirmButtonText: "ok",
      });
      setBtnLoading(false);
    } else if (Password == "") {
      Swal.fire({
        title: "Error!",
        text: "Password is empty",
        icon: "error",
        confirmButtonText: "ok",
      });
      setBtnLoading(false);
    } else {
      ApiFunctions.LoginPost({
        PhoneNumber,
        Password,
      })
        .then((res) => {
          const cookies = new Cookies(null, { path: "/" });
          cookies.set("token", res.token);
          cookies.set("Data", res.Data);
          setBtnLoading(false);

          navigate('/Dashboard')
        })
        .catch((err) => {
          Swal.fire({
            title: err.Message,
            icon: "error",
            confirmButtonText: "ok",
          });
      setBtnLoading(false);

        });
    }
  };

  return (
    <>
      <div
        class="page-wrapper"
        id="main-wrapper"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
      >
        <div class="position-relative overflow-hidden radial-gradient min-vh-100 d-flex align-items-center justify-content-center">
          <div class="d-flex align-items-center justify-content-center w-100">
            <div class="row justify-content-center w-100">
              <div class="col-md-8 col-lg-6 col-xxl-3">
                <div class="card mb-0">
                  <div class="card-body">
                    <a
                      href="./index.html"
                      class="text-nowrap logo-img text-center d-block py-3 w-100"
                    >
                      <img
                        src="../assets/images/logos/logo.png"
                        width="180"
                        alt=""
                      />
                    </a>
                    <p class="text-center">Modernize Your Sales</p>
                    <form>
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Username
                        </label>
                        <input
                          value={PhoneNumber}
                          onChange={(e) => setPhoneNumber(e.target.value)}
                          type="tel"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="phoneHelp"
                        />
                      </div>
                      <div class="mb-4">
                        <label for="exampleInputPassword1" class="form-label">
                          Password
                        </label>
                        <input
                          value={Password}
                          onChange={(e) => setPassword(e.target.value)}
                          type={showPass ? "text" : "password"}
                          class="form-control"
                          id="exampleInputPassword1"
                        />
                      </div>
                      <div class="d-flex align-items-center justify-content-between mb-4">
                        <div class="form-check">
                          <input
                            class="form-check-input primary"
                            type="checkbox"
                            value=""
                            id="flexCheckChecked"
                            checked
                          />
                          <label
                            class="form-check-label text-dark"
                            for="flexCheckChecked"
                          >
                            Remeber this Device
                          </label>
                        </div>
                        <a class="text-primary fw-bold">Forgot Password ?</a>
                      </div>
                      <a
                        onClick={!BtnLoading ? () => Submit() : null}
                        className="btn btn-primary d-flex justify-content-center align-items-center w-100 py-8 fs-4 mb-4 rounded-2"
                      >
                        {BtnLoading ? (
                          <Bars
                            height="30"
                            width="50"
                            color="#fff"
                            ariaLabel="bars-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                          />
                        ) : (
                          "Sign In"
                        )}
                      </a>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SignIn;
