import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { isTokenValid } from "./utils";
import Cookies from "universal-cookie";

const Header = () => {
  const navigate = useNavigate();
  const UseLocation = useLocation();
  const { hash, pathname, search } = UseLocation;
  const withoutSlash = pathname.replace("/", " ");
  // Capitalize the first letter of each word
  const transformedString = withoutSlash.replace(/\b\w/g, (char) =>
    char.toUpperCase()
  );

  const [TokenData, setTokenData] = useState(null);
  useEffect(() => {
    isTokenValid(navigate)
      .then((decoded) => {
        setTokenData(decoded);
      })
      .catch((err) => {});
  }, []);

  return (
    <>
      <header class="app-header">
        <nav class="navbar navbar-expand-lg navbar-light">
          <ul class="navbar-nav">
            <li class="nav-item d-block d-xl-none">
              <a
                class="nav-link sidebartoggler nav-icon-hover"
                id="headerCollapse"
                href="javascript:void(0)"
              >
                <i class="ti ti-menu-2"></i>
              </a>
            </li>
          </ul>
          {TokenData ? (
            <div
              class="navbar-collapse justify-content-end px-0"
              id="navbarNav"
            >
              <ul class="navbar-nav flex-row ms-auto align-items-center justify-content-end">
                <a class="btn btn-primary">{TokenData.Name}</a>
                <li class="nav-item dropdown">
                  <a
                    class="nav-link nav-icon-hover"
                    href="javascript:void(0)"
                    id="drop2"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      src="../assets/images/profile/user-1.jpg"
                      alt=""
                      width="35"
                      height="35"
                      class="rounded-circle"
                    />
                  </a>
                  <div
                    class="dropdown-menu dropdown-menu-end dropdown-menu-animate-up"
                    aria-labelledby="drop2"
                  >
                    <div class="message-body">
                      {/* <a href="javascript:void(0)" class="d-flex align-items-center gap-2 dropdown-item">
                      <i class="ti ti-user fs-6"></i>
                      <p class="mb-0 fs-3">My Profile</p>
                    </a>
                    <a href="javascript:void(0)" class="d-flex align-items-center gap-2 dropdown-item">
                      <i class="ti ti-mail fs-6"></i>
                      <p class="mb-0 fs-3">My Account</p>
                    </a>
                    <a href="javascript:void(0)" class="d-flex align-items-center gap-2 dropdown-item">
                      <i class="ti ti-list-check fs-6"></i>
                      <p class="mb-0 fs-3">My Task</p>
                    </a> */}
                      <a
                        onClick={() => {
                          const cookies = new Cookies(null, { path: "/" });
                          cookies.remove("token");
                        }}
                        href="/signin"
                        class="btn btn-outline-primary mx-3 mt-2 d-block"
                      >
                        Logout
                      </a>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          ) : null}
        </nav>
      </header>
    </>
  );
};

export default Header;
