import Swal from "sweetalert2";
import { ApiFunctions } from "../PostApiPromise";
import { isTokenValid } from "../utils";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import withHeader from "../components/withHeader";
import { FallingLines, InfinitySpin, Triangle } from "react-loader-spinner";

const LeadsPage = () => {
  const [LeadsList, setLeadsList] = useState([]);
  const [PageLoader, setPageLoader] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    getFollowUps();
  }, []);

  const getFollowUps = () => {
    isTokenValid(navigate)
      .then((decoded) => {
        const isAdmin = decoded.UserType == 1;

        ApiFunctions.GetLeadList({
          UserId: decoded._id,
          isAdmin,
        })
          .then((res) => {
            setLeadsList(res.Data);
            setPageLoader(false)
          })
          .catch((err) => {
            Swal.fire({
              title: err.Message,
              icon: "error",
              confirmButtonText: "ok",
            });
            setPageLoader(false)

          });
      })
      .catch(() => {});
  };

  return (
    <>
    {PageLoader?
<div
className="d-flex justify-content-center align-items-center vh-100 py-8 fs-4 mb-4"
>
<Triangle
  visible={true}
  height="120"
  width="120"
  color="#5D87FF"
  ariaLabel="triangle-loading"
  />
  </div>
:
      <div class="container-fluid">

        <div class="row">
          <div class="col-lg-12 d-flex align-items-stretch">
            <div class="card w-100">
              <div class="card-body p-4">
                <h5 class="card-title fw-semibold mb-4">Leads</h5>
                <div class="table-responsive">
                  <table class="table table-striped text-nowrap mb-0 align-middle table-hover">
                    <thead class="text-dark fs-4">
                      <tr>
                        <th class="border-bottom-0">
                          <h6 class="fw-semibold mb-0">Business Name</h6>
                        </th>
                        <th class="border-bottom-0">
                          <h6 class="fw-semibold mb-0">Email</h6>
                        </th>
                        <th class="border-bottom-0">
                          <h6 class="fw-semibold mb-0">Phone</h6>
                        </th>
                        <th class="border-bottom-0">
                          <h6 class="fw-semibold mb-0">Status</h6>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {LeadsList.map((x) => (
                        <tr>
                          <td class="border-bottom-0">
                            <h6 class="fw-semibold mb-1">{x.Data.Name}</h6>
                            <span class="fw-normal">{x.Data.Website}</span>
                          </td>
                          <td class="border-bottom-0">
                            <span class="fw-normal">{x.Data.Email}</span>
                          </td>
                          <td class="border-bottom-0">
                          <span class="fw-normal">{x.Data.Phone}</span>

                          </td>
                          <td class="border-bottom-0">
                            <div class="d-flex align-items-center gap-2">
                              <span class={["badge  rounded-3 fw-semibold",
                              x.Status == "Done"?" bg-success":
                              x.Status == "Signed"?" bg-secondary":
                            " bg-primary"]}>
                              {x.Status}
                              </span>
                            </div>
                          </td>
                       
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      }

    </>
  );
};

export default withHeader(LeadsPage);
