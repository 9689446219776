import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import Apis, { DeleteMethod } from "../Api";
import { ApiFunctions } from "../PostApiPromise";
import Swal from "sweetalert2";
import { isTokenValid } from "../utils";
import InsertLeadForm from "../components/insertLead";
import InsertFollowUp from "../components/InsertFollowup";
import withHeader from "../components/withHeader";
import { Bars, Triangle } from "react-loader-spinner";

const FollowUP = () => {
  const navigate = useNavigate();
  const [FollowUpList, setFollowUpList] = useState([]);
  const [LeadFormId, setLeadFormId] = useState(0);
  const [FollowUpFormId, setFollowUpFormId] = useState(0);
  const [PageLoader, setPageLoader] = useState(true);
  const [BtnDeclineLoading, setBtnDeclineLoading] = useState(false);

  useEffect(() => {
    getFollowUps();
  }, []);

  const getFollowUps = () => {
    isTokenValid(navigate)
      .then((decoded) => {
        const isAdmin = decoded.UserType == 1;

        ApiFunctions.GetFollowUpList({
          UserId: decoded._id,
          isAdmin,
        })
          .then((res) => {
            setFollowUpList(res.Data);
            setPageLoader(false);
          })
          .catch((err) => {
            Swal.fire({
              title: err.Message,
              icon: "error",
              confirmButtonText: "ok",
            });
            setPageLoader(false);
          });
      })
      .catch(() => {});
  };

  const notInterested = (Id) => {
    setBtnDeclineLoading(true);
    ApiFunctions.UpdateData({
      Id,
      Status: 3,
    })
      .then((res) => {
        DeleteMethod(Apis.FollowUpDeleteApi, {
          Id,
        })
          .then((res) => {
            getFollowUps();
            setLeadFormId(0);
            Swal.fire({
              title: res.Message,
              icon: "success",
              confirmButtonText: "ok",
            });
            setBtnDeclineLoading(false);
          })
          .catch((err) => {
            Swal.fire({
              title: err.Message,
              icon: "error",
              confirmButtonText: "ok",
            });
            setBtnDeclineLoading(false);
          });
      })
      .catch((err) => {
        Swal.fire({
          title: err.Message,
          icon: "error",
          confirmButtonText: "ok",
        });
        setBtnDeclineLoading(false);
      });
  };
  return (
    <>
      {PageLoader ? (
        <div className="d-flex justify-content-center align-items-center vh-100 py-8 fs-4 mb-4">
          <Triangle
            visible={true}
            height="120"
            width="120"
            color="#5D87FF"
            ariaLabel="triangle-loading"
          />
        </div>
      ) : (
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12 d-flex align-items-stretch">
              <div class="card w-100">
                <div class="card-body p-4">
                  <h5 class="card-title fw-semibold mb-4">FollowUp</h5>
                  <div class="table-responsive">
                    <table class="table table-striped text-nowrap mb-0 align-middle table-hover">
                      <thead class="text-dark fs-4">
                        <tr>
                          <th class="border-bottom-0">
                            <h6 class="fw-semibold mb-0">Name</h6>
                          </th>
                          <th class="border-bottom-0">
                            <h6 class="fw-semibold mb-0">Phone</h6>
                          </th>
                          <th class="border-bottom-0">
                            <h6 class="fw-semibold mb-0">Rating</h6>
                          </th>
                          <th class="border-bottom-0">
                            <h6 class="fw-semibold mb-0">Remarks</h6>
                          </th>
                          <th class="border-bottom-0">
                            <h6 class="fw-semibold mb-0">Date</h6>
                          </th>
                          <th class="border-bottom-0">
                            <h6 class="fw-semibold mb-0">Actions</h6>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {FollowUpList.map((x) => (
                          <>
                            <tr>
                              <td class="border-bottom-0">
                                <h6 class="fw-semibold mb-1">{x.Data.Name}</h6>
                                <span class="fw-normal">{x.Data.Website}</span>
                              </td>
                              <td class="border-bottom-0">
                                <p class="mb-0 fw-normal">{x.Data.Phone}</p>
                              </td>
                              <td class="border-bottom-0">
                                <p class="mb-0 fw-normal">
                                  Rating {x.Data.Rating}
                                </p>
                                <p class="mb-0 fw-normal">
                                  {x.Data.ReviewsCount}
                                </p>
                              </td>
                              <td class="border-bottom-0">
                                <p class="mb-0 fw-normal">{x.Remarks}</p>

                                {/* <div class="d-flex align-items-center gap-2">
                              <span class="badge bg-primary rounded-3 fw-semibold">
                                Low
                              </span>
                            </div> */}
                              </td>
                              <td class="border-bottom-0">
                                <p class="mb-0 fw-normal">{x.Date}</p>
                              </td>
                              <td class="border-bottom-0">
                                <div style={{ display: "flex" }}>
                                  <div
                                    onClick={() => {
                                      if (LeadFormId == x._id) {
                                        setLeadFormId(0);
                                      } else {
                                        setLeadFormId(x._id);
                                      }
                                      setFollowUpFormId(0);

                                    }}
                                    style={{ padding: 5, cursor: "pointer" }}
                                  >
                                    <img
                                      src="../assets/images/icon/handshake.png"
                                      style={{ width: 40 }}
                                      alt="Girl in a jacket"
                                    />
                                  </div>

                                  <div
                                    onClick={() => {
                                      if (FollowUpFormId == x._id) {
                                        setFollowUpFormId(0);
                                      } else {
                                        setFollowUpFormId(x._id);
                                      }
                                      setLeadFormId(0);

                                    }}
                                    style={{ padding: 5, cursor: "pointer" }}
                                  >
                                    <img
                                      src="../assets/images/icon/upcoming.png"
                                      style={{ width: 40 }}
                                    />
                                  </div>

                                  <div
                                    class="d-flex justify-content-center align-items-center"
                                    onClick={() => {
                                      !BtnDeclineLoading
                                        ? notInterested(x._id)
                                        : Swal.fire({
                                            title: "Please wait",
                                            icon: "warning",
                                            confirmButtonText: "ok",
                                            timer: 1500,
                                          });
                                    }}
                                    style={{ padding: 5, cursor: "pointer" }}
                                  >
                                    {BtnDeclineLoading ? (
                                      <Bars
                                        height="20"
                                        width="50"
                                        color="#5D87FF"
                                        ariaLabel="bars-loading"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                        visible={true}
                                      />
                                    ) : (
                                      <img
                                        src="../assets/images/icon/declined.png"
                                        style={{ width: 40 }}
                                      />
                                    )}
                                  </div>
                                </div>
                              </td>
                            </tr>

                            {LeadFormId == x._id ? (
                              <tr>
                                <td colspan="5">
                                  <InsertLeadForm
                                    TempBData={x.Data}
                                    setFormVisible={() => {
                                      DeleteMethod(Apis.FollowUpDeleteApi, {
                                        Id: x._id,
                                      })
                                        .then((res) => {
                                          Swal.fire({
                                            title: res.Message,
                                            icon: "success",
                                            confirmButtonText: "ok",
                                          });
                                          getFollowUps();
                                          setLeadFormId(0);
                                        })
                                        .catch((err) => {
                                          Swal.fire({
                                            title: err.Message,
                                            icon: "error",
                                            confirmButtonText: "ok",
                                          });
                                        });
                                    }}
                                  />
                                </td>
                              </tr>
                            ) : null}
                            {FollowUpFormId == x._id ? (
                              <tr>
                                <td colspan="5">
                                  <InsertFollowUp
                                    id={x._id}
                                    TempBData={x.Data}
                                    setFormVisible={() => {
                                      setFollowUpFormId(0);
                                      getFollowUps();
                                    }}
                                  />
                                </td>
                              </tr>
                            ) : null}
                          </>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default withHeader(FollowUP);
