import Apis, { PostMethod, host } from "./Api";


const LoginPost = (body) => {
    return new Promise((res, rej) => {
        PostMethod(Apis.LoginApi, body)
            .then((response) => {
                res(response);
            })
            .catch((err) => {
                console.log(err)
                rej(err)
            })
    })

};


const LeadPost = (body) => {
    return new Promise((res, rej) => {
        PostMethod(Apis.InsertLeadApi, body)
            .then((response) => {
                res(response);
            })
            .catch((err) => {
                console.log(err)
                rej(err)
            })
    })

};


const FollowUpPost = (body) => {
    return new Promise((res, rej) => {
        PostMethod(Apis.FollowUpInsert, body)
            .then((response) => {
                res(response);
            })
            .catch((err) => {
                console.log(err)
                rej(err)
            })
    })
};
const UpdateFollowUpPost = (body) => {
    return new Promise((res, rej) => {
        PostMethod(Apis.FollowUpUpdateApi, body)
            .then((response) => {
                res(response);
            })
            .catch((err) => {
                console.log(err)
                rej(err)
            })
    })
};


const UpdateData = (body) => {
    return new Promise((res, rej) => {
        PostMethod(Apis.DataUpdate, body)
            .then((response) => {
                res(response);
            })
            .catch((err) => {
                console.log(err)
                rej(err)
            })
    })
};

const GetFollowUpList = (body) => {
    return new Promise((res, rej) => {
        PostMethod(Apis.FollowUpListApi, body)
            .then((response) => {
                res(response);
            })
            .catch((err) => {
                console.log(err)
                rej(err)
            })
    })
};

const GetLeadList = (body) => {
    return new Promise((res, rej) => {
        PostMethod(Apis.LeadListApi, body)
            .then((response) => {
                res(response);
            })
            .catch((err) => {
                console.log(err)
                rej(err)
            })
    })
};



const ApiFunctions = {
    LoginPost,
    LeadPost,
    FollowUpPost,
    UpdateData,
    GetFollowUpList,
    UpdateFollowUpPost,
    GetLeadList
}

export {
    ApiFunctions
}