
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import InsertLeadForm from "../components/insertLead";
import { isTokenValid } from "../utils";
import InsertFollowUp from "../components/InsertFollowup";
import { ApiFunctions } from "../PostApiPromise";
import Swal from "sweetalert2";
import withHeader from "../components/withHeader";
import Chart from "react-apexcharts";
import chartData from "../chartdata";
import { Bars } from "react-loader-spinner";

const io = require("socket.io-client");

var socket = io("wss://ws.swiftsalesync.world", {
  transports: ["websocket", "polling", "flashsocket"],
});

export { socket };

socket.on("connect_error", (err) => {
  console.log(`connect_error due to ${err.message}`);
});





const Dashboard = () => {
  const navigate = useNavigate();
  const [TempBData, setTempBData] = useState(null);
  const [BtnNotIntrLoading, setBtnNotIntrLoading] = useState(false);

  const [formVisible, setFormVisible] = useState("");

  socket.on("BusinesData", (Data) => {
    setTempBData(Data);
  });

  useEffect(() => {
    socket.disconnect();
    socket.connect();
    isTokenValid(navigate)
      .then(() => {})
      .catch(() => {});
  }, []);

  const notInterested = () => {
    ApiFunctions.UpdateData({
      Id: TempBData._id,
      Status: 3,
    })
      .then((res) => {
        Swal.fire({
          title: res.Message,
          icon: "success",
          confirmButtonText: "ok",
        });
        socket.emit("RemoveFromData");
        socket.disconnect();
        socket.connect();
        setBtnNotIntrLoading(false)
        
      })
      .catch((err) => {
        setBtnNotIntrLoading(false)

        Swal.fire({
          title: err.Message,
          icon: "error",
          confirmButtonText: "ok",
        });
      });
  };
  const CopyNumber = (number) =>{
    navigator.clipboard.writeText(`+1${number}`)
    .then(() => {
      Swal.fire({
        title: "Copied",
        icon: "success",
        timer:1000
      });
    })
    .catch(err => {
      console.error('Error copying text: ', err);
      // Handle error, show error message to the user, etc.
    });
  }
  return (
    <>
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-8 d-flex align-items-strech">
            <div class="card w-100">
              <div class="card-body">
                <div class="d-sm-flex d-block align-items-center justify-content-between mb-9">
                  <div class="mb-3 mb-sm-0">
                    <h5 class="card-title fw-semibold">Sales Overview</h5>
                  </div>
                  <div>
                    <select class="form-select">
                      <option value="1">March 2023</option>
                      <option value="2">April 2023</option>
                      <option value="3">May 2023</option>
                      <option value="4">June 2023</option>
                    </select>
                  </div>
                </div>
                <div className="mixed-chart">
                  <Chart
                    options={chartData.basic.options}
                    series={chartData.basic.series}
                    type="bar"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="row">
              <div class="col-lg-12">
                <div class="card overflow-hidden">
                  <div class="card-body p-4">
                    <h5 class="card-title mb-9 fw-semibold">Yearly Breakup</h5>
                    <div class="row align-items-center">
                      <div class="col-8">
                        <h4 class="fw-semibold mb-3">$36,358</h4>
                        <div class="d-flex align-items-center mb-3">
                          <span class="me-1 rounded-circle bg-light-success round-20 d-flex align-items-center justify-content-center">
                            <i class="ti ti-arrow-up-left text-success"></i>
                          </span>
                          <p class="text-dark me-1 fs-3 mb-0">+9%</p>
                          <p class="fs-3 mb-0">last year</p>
                        </div>
                        <div class="d-flex align-items-center">
                          <div class="me-4">
                            <span class="round-8 bg-primary rounded-circle me-2 d-inline-block"></span>
                            <span class="fs-2">2023</span>
                          </div>
                          <div>
                            <span class="round-8 bg-light-primary rounded-circle me-2 d-inline-block"></span>
                            <span class="fs-2">2023</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="d-flex justify-content-center">
                   
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-12">
                <div class="card">
                  <div class="card-body">
                    <div class="row alig n-items-start">
                      <div class="col-8">
                        <h5 class="card-title mb-9 fw-semibold">
                          {" "}
                          Monthly Earnings{" "}
                        </h5>
                        <h4 class="fw-semibold mb-3">$6,820</h4>
                        <div class="d-flex align-items-center pb-1">
                          <span class="me-2 rounded-circle bg-light-danger round-20 d-flex align-items-center justify-content-center">
                            <i class="ti ti-arrow-down-right text-danger"></i>
                          </span>
                          <p class="text-dark me-1 fs-3 mb-0">+9%</p>
                          <p class="fs-3 mb-0">last year</p>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="d-flex justify-content-end">
                          <div class="text-white bg-secondary rounded-circle p-6 d-flex align-items-center justify-content-center">
                            <i class="ti ti-currency-dollar fs-6"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="earning"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {TempBData ? (
          <div class="row mt-4">
            <div class="col-lg-12 mb-lg-0 mb-4">
              <div class="card ">
                <div class="card-header pb-0 p-3">
                  <div class="d-flex justify-content-between">
                    <h6 class="mb-2">Sales by Country</h6>
                  </div>
                </div>
                <div class="table-responsive">
                  <table class="table align-items-center ">
                    <tbody>
                      <tr>
                        <td class="w-30">
                          <div class="d-flex px-2 py-1 align-items-center">
                            <div>
                              <img
                                src="../assets/images/america-flag.png"
                                alt="Country flag"
                                width={50}
                                height={50}
                                style={{ objectFit: "contain" }}
                              />
                            </div>
                            <div class="ms-4">
                              <p class="text-xs font-weight-bold mb-0">
                                Business Name:
                              </p>
                              <h6 class="text-sm mb-0">{TempBData.Name}</h6><br/>
                              <a target="_blank" href={`http://${TempBData.Website}`}><p class="text-sm mb-0">{TempBData.Website}</p></a>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="text-center">
                            <p class="text-xs font-weight-bold mb-0">
                              Phone Number:
                            </p>
                            <h6 onClick={()=>CopyNumber(TempBData.Phone)} style={{cursor:"grab"}} class="text-sm mb-0">{TempBData.Phone}</h6>
                          </div>
                        </td>
                        <td>
                          <div class="text-center">
                            <p class="text-xs font-weight-bold mb-0">
                              ReviewsCount:
                            </p>
                            <h6 class="text-sm mb-0">
                              {TempBData.ReviewsCount}
                            </h6>
                          </div>
                        </td>
                        <td class="align-middle text-sm">
                          <div class="col text-center">
                            <p class="text-xs font-weight-bold mb-0">Rating:</p>
                            <h6 class="text-sm mb-0">{TempBData.Rating}</h6>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="w-30"></td>
                        <td>
                          <div class="col text-center">
                            <a href="#leadform">
                              <button
                                onClick={() => {
                                  if (formVisible == "lead") {
                                    setFormVisible("");
                                  } else {
                                    setFormVisible("lead");
                                  }
                                }}
                                type="button"
                                class="btn btn-secondary m-1"
                              >
                                Lead
                              </button>
                            </a>
                          </div>
                        </td>
                        <td>
                          <div class="col text-center">
                            <a href="#followUpForm">
                              <button
                                onClick={() => {
                                  if (formVisible == "followup") {
                                    setFormVisible("");
                                  } else {
                                    setFormVisible("followup");
                                  }
                                }}
                                type="button"
                                class="btn btn-secondary m-1"
                              >
                                Follow Up
                              </button>
                            </a>
                          </div>
                        </td>
                        <td class="align-middle text-sm">
                          <div class="col text-center">
                            <button
                              onClick={() => {
                                setBtnNotIntrLoading(true)
                                notInterested();
                              }}
                              type="button"
                              class="btn btn-secondary m-1 d-flex justify-content-center align-items-center"
                            >
                              {BtnNotIntrLoading ? (
                      <Bars
                        height="20"
                        width="50"
                        color="#fff"
                        ariaLabel="bars-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                      />
                    ) : (
                      "Not not Interested"
                    )}
                              
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {formVisible == "lead" ? (
          <InsertLeadForm
            TempBData={TempBData}
            setFormVisible={setFormVisible}
          />
        ) : null}
        {formVisible == "followup" ? (
          <InsertFollowUp
            TempBData={TempBData}
            setFormVisible={setFormVisible}
          />
        ) : null}
      </div>
    </>
  );
};

export default withHeader(Dashboard);
