import logo from "./logo.svg";
import "./App.css";

import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SignIn from "./pages/sign_in";
import Dashboard from "./pages/dashboard";
import FollowUP from "./pages/followup";
import LeadsPage from "./pages/leads";
import Header from "./header";

function App() {
  return (
    <BrowserRouter>

      <Routes>
        <Route path="/signin" element={<SignIn />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/followup" element={<FollowUP />} />
        <Route path="/leads" element={<LeadsPage />} />
        
        <Route path="/" element={<SignIn />} />
        <Route path="*" element={<SignIn />} />
      </Routes>

 
    </BrowserRouter>
  );
}

export default App;
