import React, { useState } from "react";
import Header from "../header";
import { useLocation, useNavigate } from "react-router-dom";

const withHeader = (WrappedComponent) => {
    const WithLoading = (props)=> {
    
      const navigate = useNavigate();
      const [activePage, setActivePage] = useState("") 
      const { hash, pathname, search } = useLocation();
      
      useState(()=>{
        const withoutSlash = pathname.replace('/', ' ');
        let lowername = withoutSlash.toLowerCase();
        lowername = lowername.replace(/\s/g, '');
        setActivePage(lowername)
      },[])
   

        return (
            <>
  <div class="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
    data-sidebar-position="fixed" data-header-position="fixed">
    <aside class="left-sidebar">
      <div>
        <div class="brand-logo d-flex align-items-center justify-content-between">
          <a href="./index.html" class="text-nowrap logo-img">
            <img src="../assets/images/logos/logo.png" width="180" alt="" />
          </a>
          <div class="close-btn d-xl-none d-block sidebartoggler cursor-pointer" id="sidebarCollapse">
            <i class="ti ti-x fs-8"></i>
          </div>
        </div>
        <nav class="sidebar-nav scroll-sidebar" data-simplebar="">
          <ul id="sidebarnav">
            <li class="nav-small-cap">
              <i class="ti ti-dots nav-small-cap-icon fs-4"></i>
              <span class="hide-menu">Home</span>
            </li>
            <li class="sidebar-item">
              <a onClick={()=>navigate('/Dashboard')} class={activePage == "dashboard"? "sidebar-link active":"sidebar-link"}   aria-expanded="false">
                <span>
                  <i class="ti ti-layout-dashboard"></i>
                </span>
                <span class="hide-menu">Dashboard</span>
              </a>
            </li>
            <li class="nav-small-cap">
              <i class="ti ti-dots nav-small-cap-icon fs-4"></i>
              <span class="hide-menu">For agent</span>
            </li>
            <li class="sidebar-item">
              <a onClick={()=>navigate('/FollowUp')}  class={activePage == "followup"? "sidebar-link active":"sidebar-link"} aria-expanded="false">
                <span>
                  <i class="ti ti-article"></i>
                </span>
                <span class="hide-menu">Follow up</span>
              </a>
            </li>
       
            <li class="sidebar-item">
              <a onClick={()=>navigate('/Leads')}  class={activePage == "leads"? "sidebar-link active":"sidebar-link"} aria-expanded="false">
                <span>
                  <i class="ti ti-cards"></i>
                </span>
                <span class="hide-menu">Leads</span>
              </a>
            </li>
          
           
          
          </ul>
          <div class="unlimited-access hide-menu  position-relative mb-7 mt-5 rounded">
         
          </div>
        </nav>
 
      </div>
    
    </aside>

    <div class="body-wrapper">

     
    
        <Header />
          <WrappedComponent
            {...props}
          />
          
          </div>
          </div>
          </>
        );
      
    }
  
  
    return WithLoading;
  };
  
  export default withHeader;