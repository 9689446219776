import { useState } from "react";
import CheckNullAndEmpty, { MandatoryField, isTokenValid } from "../utils";
import { json, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { ApiFunctions } from "../PostApiPromise";
import { socket } from "../pages/dashboard";
import { Bars } from "react-loader-spinner";

const services = ["Website Development", "On Page SEO"];
const InsertLeadForm = ({ TempBData, setFormVisible }) => {
  const [UserName, SetUserName] = useState("");
  const [Email, SetEmail] = useState("");
  const [WhatsappNumber, setWhatsappNumber] = useState("");
  const [SelectedServices, setSelectedServices] = useState([]);
  const [Bill, setBill] = useState(0);
  const [Remarks, setRemarks] = useState("");
  const [Services, setServices] = useState(services);
  const navigate = useNavigate();
  const [BtnLoading, setBtnLoading] = useState(false);

  const SaveLead = () => {
    setBtnLoading(true);
    isTokenValid(navigate)
      .then((data) => {
        const body = {
          Name: UserName,
          BusinessName: TempBData.Name,
          Email,
          Phone: WhatsappNumber,
          ArrayOfItems: SelectedServices,
          TotalBill: JSON.parse(Bill),
          DataId: TempBData._id,
          UserId: data._id,
          Remarks,
        };

        CheckNullAndEmpty(body, MandatoryField.Lead.Insert)
          .then((res) => {
            ApiFunctions.LeadPost(body)
              .then((res) => {
                Swal.fire({
                  title: res.Message,
                  icon: "success",
                  confirmButtonText: "ok",
                });
                setFormVisible("");
                socket.emit("RemoveFromData");
                socket.disconnect();
                socket.connect();
                setBtnLoading(false);
              })
              .catch(() => {
                Swal.fire({
                  title: res.Message,
                  icon: "error",
                  confirmButtonText: "ok",
                });
                setBtnLoading(false);
              });
          })
          .catch((err) => {
            setBtnLoading(false);

            Swal.fire({
              title: "Error!",
              text: err.Message,
              icon: "error",
              confirmButtonText: "ok",
            });
          });
      })
      .catch(() => {
        setBtnLoading(false);
      });
  };
  return (
    <>
      <div id="leadform" class="row mt-4">
        <div class="col-lg-12 mb-lg-0 mb-4">
          <div class="card">
            <div class="card-header pb-0">
              <div class="d-flex align-items-center">
                <p class="form-label">Lead</p>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                  <div class="mb-3">
                    <label class="form-label">Username</label>
                    <input
                      class="form-control"
                      type="text"
                      onfocus="focused(this)"
                      onfocusout="defocused(this)"
                      onChange={(e) => SetUserName(e.target.value)}
                      value={UserName}
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="form-label">Email address</label>
                    <input
                      class="form-control"
                      type="email"
                      onfocus="focused(this)"
                      onfocusout="defocused(this)"
                      onChange={(e) => SetEmail(e.target.value)}
                      value={Email}
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="example-text-input" class="form-label">
                      Whatsapp number
                    </label>
                    <input
                      class="form-control"
                      type="text"
                      onfocus="focused(this)"
                      onfocusout="defocused(this)"
                      onChange={(e) => setWhatsappNumber(e.target.value)}
                      value={WhatsappNumber}
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="example-text-input" class="form-label">
                      Remarks
                    </label>
                    <textarea
                      class="form-control"
                      type="text"
                      onfocus="focused(this)"
                      onfocusout="defocused(this)"
                      onChange={(e) => setRemarks(e.target.value)}
                      value={Remarks}
                    />
                  </div>
                </div>
              </div>
              <hr class="horizontal dark" />
              <p class="text-uppercase text-sm">Bill Information</p>
              <div class="row">
                <div class="col-md-12"></div>
                {Services.map((x) => (
                  <div class="col-md-4">
                    <div class="form-group">
                      <div class="form-check form-check-info text-start">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value={x}
                          checked={SelectedServices.includes(x)}
                          id="flexCheckDefault"
                          onClick={(x) => {
                            if (SelectedServices.includes(x.target.value)) {
                              setSelectedServices((current) =>
                                current.filter((y) => {
                                  // 👇️ remove object that has id equal to 2
                                  return y != x.target.value;
                                })
                              );
                            } else {
                              setSelectedServices((current) => [
                                ...current,
                                x.target.value,
                              ]);
                            }
                          }}
                        />
                        <label class="form-label" for="flexCheckDefault">
                          <a
                            href="javascript:;"
                            class="text-dark font-weight-bolder"
                          >
                            {x}
                          </a>
                        </label>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <hr class="horizontal dark" />
              <div class="row">
                <div class="col-md-6">
                  <div class="mb-3">
                    <label for="example-text-input" class="form-label">
                      Bill
                    </label>
                    <input
                      class="form-control"
                      type="number"
                      onfocus="focused(this)"
                      onfocusout="defocused(this)"
                      onChange={(x) => setBill(x.target.value)}
                      value={Bill}
                    />
                  </div>
                </div>
                <div class="col-md-6"></div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <button
                    onClick={() => !BtnLoading? SaveLead():null}
                    class="btn btn-primary m-1 d-flex justify-content-center align-items-center"
                  >
                    {BtnLoading ? (
                      <Bars
                        height="20"
                        width="50"
                        color="#fff"
                        ariaLabel="bars-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                      />
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InsertLeadForm;
